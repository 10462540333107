import * as React from 'react';
import clsx from 'clsx';
import { canUseDOM } from 'exenv';
import { Link } from 'gatsby';
import * as types from 'src/types';
import useView from 'src/hooks/useView';
import { MenuItem, Props as MenuItemProps } from './MenuItem';
import { MenuList } from './MenuList';
import { Hamburger } from '../ui/hamburger/hamburger';
import * as style from './Menu.module.scss';

import navLogo from 'src/assets/media/Max_resources_logo.png';

const MemoizedMenuItem = React.memo(MenuItem);

/**
 * Create new type with modified children by omitting `MenuItemProps` children
 * property and assigning new `MenuItemData` type which refers to itself for
 * recursive mapping.
 */
export type MenuItemData = {
  children?: MenuItemData[];
  id: string;
} & Omit<MenuItemProps, 'children'>;

export type Props = {
  brandLogo?: string;
  brandName?: string;
  menu?: MenuItemData[];
  partnerLogo?: string;
  partnerName?: string;
  withPartner?: boolean;
};

export const Menu = (props: Props) => {
  const {
    // brandLogo = navLogo,
    brandName = 'Max Resources',
    menu = [],
    partnerLogo,
    partnerName,
    withPartner = false,
  } = props;
  const [showNav, setShowNav] = React.useState(false);

  const navbarRef = React.useRef<HTMLDivElement>(null);
  const view = useView();

  /**
   * Build menu list component tree.
   *
   * @param {MenuItemData} items - List of menu items
   * @returns Menu component tree
   */
  const mapNav = (items: MenuItemData[]) => {
    if (!items?.length) return null;

    return (
      <MenuList isHamburger={types.Sizes.sm === view}>
        {items.map((item) => (
          <MemoizedMenuItem
            key={item.id}
            to={item.to}
            label={item.label}
            hasSubmenu={!!item?.children?.length}
            special={item.special}
            onClick={handleNavItemClick}>
            {item.children?.length && mapNav(item.children)}
          </MemoizedMenuItem>
        ))}
      </MenuList>
    );
  };

  /**
   * Removes `focus` event when menu item is clicked or not re-rendered.
   */
  const handleNavItemClick = React.useCallback((e: React.MouseEvent) => {
    if (document.activeElement instanceof HTMLElement) {
      document.activeElement.blur();

      setShowNav(false);
    }
  }, []);

  /**
   * Toggle navigation show state.
   */
  const handleNavToggle = () => {
    setShowNav((prevState) => !prevState);
  };

  /**
   * Add solid or transparent background on navbar.
   */
  const handleScroll = React.useCallback(() => {
    if (!navbarRef.current) return;

    if (window.pageYOffset > navbarRef?.current?.offsetHeight) {
      navbarRef.current.classList.add('is-opaque');
      return;
    }

    navbarRef.current.classList.remove('is-opaque');
  }, []);

  /**
   * Bind scrolling handler and do cleanup on unmount.
   */
  React.useEffect(() => {
    if (!window) return;

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  /**
   * Toggle expanded mobile navigation.
   */
  React.useEffect(() => {
    if (types.Sizes.lg !== view) return;
    setShowNav(false);
  }, [view]);

  /**
   * Apply necessary css class to body to disable page scrolling when mobile
   * navigation is open.
   */
  React.useEffect(() => {
    if (showNav) {
      document.body.classList.add('body-overflow-hidden');
    } else {
      document.body.classList.remove('body-overflow-hidden');
    }
  }, [showNav]);

  /**
   * Make sure component is rendered only on client side.
   */
  if (!canUseDOM) return null;

  return (
    <div
      ref={navbarRef}
      className={clsx(style.wrapper, {
        'is-active': showNav && types.Sizes.sm === view,
      })}>
      <div className={clsx(style.container, 'container container--fhd')}>
        <div className={clsx(style.row, 'row')}>
          <div className={clsx(style.brand)}>
            <Link to="/">
              <img
                className={clsx(style.brandLogo)}
                src={navLogo}
                alt={brandName}
              />
            </Link>
            {withPartner && (
              <>
                <span className={clsx(style.brandPartnerWith)}>with</span>
                <img
                  className={clsx(style.brandPartnerLogo)}
                  src={partnerLogo}
                  alt={partnerName}
                />
              </>
            )}
          </div>

          {types.Sizes.sm === view && (
            <div className={clsx(style.navMobile)}>
              <Hamburger
                isActive={showNav}
                isDarkMode={true}
                handleClick={handleNavToggle}
              />
            </div>
          )}

          <nav className={clsx(style.navWrapper)} aria-label="Main Navigation">
            {mapNav(menu)}
          </nav>
        </div>
      </div>
    </div>
  );
};
